import React from 'react';
import styled from 'styled-components';

const ContactTextWrapper = styled.div`
  text-align: center;
  margin: auto 2.5%;
  max-width: 500px;
`

const ContactTextTitle = styled.h3`
  text-align: center
`

export default function ContactText({title, name, position, email}) {
  return (
    <ContactTextWrapper>
      <ContactTextTitle>{title}</ContactTextTitle>
      <p>
         {name} <br/>
         <i>{position}</i> <br/>
         {email}
      </p>
    </ContactTextWrapper>
  )
}
