import React from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import Theme from '../styles/Theme';
import ContactText from '../components/ContactText';
import Layout from '../components/Layout';
import GeometricBackgroundWrapper from '../components/containers/GeometricBackgroundWrapper';
import SectionBlock from '../components/containers/SectionBlock';
import {Col, Row} from 'react-bootstrap';
import useContactPage from '../hooks/useContactPage';

const theme = createTheme({
  palette:{
    primary:{
      main: Theme.colors.compassGreen,
    },
    secondary:{
      main: Theme.colors.compassGreen
    }
  }
});

const SubmitButtonWrapper = styled.div`
  margin-top: ${props => props.theme.padding.item.standard};
  text-align: right;
`

const SubmitButton = styled(Button)`
  color: white !important;
`

export default function Contact({location}) {

  const {forServices, forStudents} = useContactPage();

  const emailConstants = {
    serviceId: "service_7iexc0d",
    templateId: "template_92zorfk",
    userId: "user_MXOVG4b0jQFX1EV8w8Sqt"
  };

  function sendEmail(email) {
    email.preventDefault(); // IMPORTANT
    emailjs.sendForm(emailConstants.serviceId, emailConstants.templateId, email.target, emailConstants.userId)
      .then((result) => {
        window.location.reload()
      }, (error) => {
        console.log(error.text)
      });
    alert("Your message has been successfully sent!")
  }

  return (
    <Layout location={location}>

      <GeometricBackgroundWrapper
        shape={'circle'}
        size={'500px'}
        color={Theme.colors.brightLightGreen + '88'}
        top={'0px'}
        left={'20vw'}
      >
        <SectionBlock>
          <Row>
            <Col md={6}>
              <ContactText title="For Partnerships and Services" name={forServices.name}
                           position={forServices.position} email={forServices.email} />
              <br/><br/>
              <ContactText title="For Students" name={forStudents.name}
                           position={forStudents.position} email={forStudents.email} />
            </Col>

            <Col md={6}>
              <form onSubmit={sendEmail}>
                <ThemeProvider theme={theme}>
                  <TextField id="fname" label="Full Name" variant="outlined" margin="normal" fullWidth required></TextField>
                  <TextField id="email" label="Email" variant="outlined" margin="normal" fullWidth required></TextField>
                  <TextField id="subject" label="Subject" variant="outlined" margin="normal" fullWidth required></TextField>
                  <TextField id="message" label="Message" variant="outlined" margin="normal" rows={5} multiline fullWidth required></TextField>

                  <SubmitButtonWrapper>
                    <SubmitButton size={'large'} id="button" type="submit" variant="contained" color="primary">Submit</SubmitButton>
                  </SubmitButtonWrapper>
                </ThemeProvider>
              </form>
            </Col>
          </Row>
        </SectionBlock>
      </GeometricBackgroundWrapper>
    </Layout>
  )
}
